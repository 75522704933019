<template>
  <el-select
    v-model="localValue"
    :size="size"
    :multiple="multiple"
    remote
    clearable
    reserve-keyword
    placeholder="Единица измерения"
    :remote-method="remoteMethod"
  >
      <el-option
        v-for="item in units"
        :key="item.id"
        :label="item.symbol"
        :value="item.id"
      >
      </el-option>
  </el-select>

</template>

<script>


import {paginator} from "@/mixins/paginator";

export default {
  name: "element-scale-unit-select",
  mixins: [],
  components: {},
  props: {
    value: {},
    currentUnit: {type: Object},
    size: { type: String, default: '' },
    multiple: { type: Boolean, default: false }
  },

  data() {
    return {
      loading: false,
      query: null,
      units: [],
    }
  },
  mounted() {
    this.remoteMethod()
  },
  watch: {},
  computed: {
    localValue: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.selected(newValue)
      }
    }
  },
  methods: {
    remoteMethod(query) {
      this.query = query;
      this.units = [];
      this.getData();
    },

    getData() {
      this.loading = true;
      this.$api.library.getScalesUnits()
        .then(data => {
          // У нас может быть удаленная единица. Нужно ее тоже пушнуть в массив
          if( this.currentUnit ){
            if( !data.scales_units.find(t=>t.id===this.currentUnit.id) ){
              data.scales_units.push({
                id: this.currentUnit.id,
                symbol: this.currentUnit.symbol,
              })
            }
          }

          this.units = data.scales_units;
        })
        .finally(() => {
          this.loading = false;
        })
    },

    selected(value) {
      this.$emit('input', value);
      this.$emit('selected', value)
    }
  },

}
</script>
